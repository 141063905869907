import type { Contact } from '../../../types';

export const NextflexContacts: Contact[] = [
  {
    name: 'Julien MICHEL',
    email: 'julien.michel@engie.com',
    role: 'sales-contact',
    type: 'NEXTFLEX',
    phoneNumber: '+33144225529',
  },
  {
    name: 'NEXTFLEX',
    email: 'nextflex@engie.com',
    role: 'on-call-duty',
    type: 'NEXTFLEX',
    phoneNumber: '+33149186910',
  },
];
