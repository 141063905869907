import { format as dateFnsFormat, differenceInMinutes, isBefore, isValid, parseISO } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import i18next from 'i18next';
import { isDate, isNumber } from 'lodash';
import { z } from 'zod';

const PLACEHOLDER = '-';
const LOCALES = { fr, en: enGB };

const parseDateLike = (timestamp: DateLike) => (isNumber(timestamp) || isDate(timestamp) ? new Date(timestamp) : parseISO(timestamp));

export const dateTimeFormatter = ({ timestamp, format, locale = i18next.language }: DateFormatter) => {
  const value = parseDateLike(timestamp);
  const options = { ...PATTERNS[format] } as Intl.DateTimeFormatOptions;

  return isValid(value) ? Intl.DateTimeFormat(locale, options).format(value) : PLACEHOLDER;
};

export const durationFormatter = ({ from, to }: DurationProps) => {
  const start = parseDateLike(from);
  const end = parseDateLike(to);

  if (!isValid(start) || !isValid(end)) {
    return PLACEHOLDER;
  }

  const timeStart = dateTimeFormatter({ timestamp: from, format: 'TIME' });
  const timeEnd = dateTimeFormatter({ timestamp: to, format: 'TIME' });

  if (isBefore(end, start)) {
    return `${timeStart} (0h 0m)`;
  }

  const durationInMinutes = differenceInMinutes(end, start);
  const minutes = durationInMinutes % 60;
  const hours = (durationInMinutes - minutes) / 60;
  const duration = `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`;

  return `${timeStart}-${timeEnd} (${duration})`;
};

const DATE: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
const TIME: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23',
};
const PATTERNS = {
  DATE,
  TIME,
};

const DateLike = z.union([z.string().datetime(), z.date(), z.number()]);

export const dateTimeFormatI18n = ({ date, formatString, locale = i18next.language }: DateTimeFormatI18N) => {
  return dateFnsFormat(date, formatString, { locale: LOCALES[locale as keyof typeof LOCALES] });
};

type DateTimePattern = keyof typeof PATTERNS;
export type DateLike = z.infer<typeof DateLike>;
type DateFormatter = {
  timestamp: DateLike;
  format: DateTimePattern;
  locale?: string;
};
type DurationProps = {
  from: DateLike;
  to: DateLike;
};

type DateTimeFormatI18N = {
  date: Date;
  formatString: string;
  locale?: string;
};
