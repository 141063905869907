import { z } from 'zod';

const configSchema = z.object({
  env: z.enum(['dev', 'uat', 'demo', 'prod']),
});

export type Config = z.infer<typeof configSchema>;

/**
 * Method to load the config from the config.json file
 * @returns {Promise<Config>} The config object
 */
export const loadConfig = async (): Promise<Config> => {
  const response = await fetch('/config.json');
  const configData = await response.json();

  const config = configSchema.parse(configData);
  return config;
};
