import type { DropdownElement } from '../../types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NJBadge } from '@engie-group/fluid-design-system-react';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';

import { useConfig } from '../../hooks';
import { globalSelector, logout, userSelector, useTypedDispatch } from '../../store';
import { Dropdown } from '../dropdown';
import styles from './Header.module.scss';

export const Header: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useTypedDispatch();
  const config = useConfig();

  const global = useSelector(globalSelector);
  const user = useSelector(userSelector);

  const languages: DropdownElement[] = ['fr', 'en']
    .filter((language) => language !== i18n.language)
    .map((language) => ({
      label: t(language as 'fr' | 'en'),
      onClick: () => {
        i18n.changeLanguage(language);
      },
    }));

  const account: DropdownElement[] = [
    // {
    //   label: t('header-menu.profile'),
    //   onClick: () => {
    //     navigate('/profile');
    //   },
    // },
    {
      label: t('header-menu.logout'),
      onClick: () => {
        Sentry.setUser(null);
        dispatch(logout({}));
      },
    },
  ];

  return (
    <header className="sticky-top bg-gray-200 pt-1 px-2 z-1" data-testid="header-component">
      <div className="d-flex justify-content-between align-items-center">
        <div className="t-kilo m-0">{global.pageTitle}</div>
        <div className="flex-fill">
          <div className="d-flex gap-10 justify-content-end align-items-center">
            <Dropdown label={t(i18n.language as 'fr' | 'en')} elements={languages} icon="language" dataTestId="dropdown-menu-language" />
            <Dropdown label={user.name || ''} elements={account} icon="account_circle" dataTestId="dropdown-menu-user" />
          </div>
        </div>
        {config.env !== 'prod' ? (
          <NJBadge variant="information">
            Environment:&nbsp;<b>{config.env.toUpperCase()}</b>
          </NJBadge>
        ) : null}
      </div>
      <hr className={clsx([styles['border']])} />
    </header>
  );
};
