import type { CalendarUnavailability } from '../types';

import { differenceInSeconds, endOfDay, format, formatISO, startOfDay } from 'date-fns';

export function adjustUnavailabilityData(unavailabilities: CalendarUnavailability[]): CalendarUnavailability[] {
  const adjustedUnavailabilities: CalendarUnavailability[] = [];

  unavailabilities.forEach((unavailability) => {
    const start = new Date(unavailability.declarationStart);
    const end = new Date(unavailability.declarationEnd);

    function earliest(date: Date, end: Date) {
      return date < end ? date : end;
    }

    while (start < end) {
      const newUnavailability = { ...unavailability };
      newUnavailability.declarationStart = formatISO(start);
      newUnavailability.declarationEnd = formatISO(earliest(endOfDay(start), end));
      adjustedUnavailabilities.push(newUnavailability);
      const startOfNextDay = startOfDay(new Date(start).setDate(start.getDate() + 1));
      start.setTime(startOfNextDay.getTime());
    }
  });

  return adjustedUnavailabilities;
}

export function getMonthNames(locale: Locale) {
  const months = [];
  for (let month = 0; month < 12; month++) {
    const date = new Date(0, month); // Year 0 is used to avoid leap year issues
    months.push(format(date, 'MMMM', { locale }));
  }
  return months;
}

export function isFullDay(from: Date, to: Date) {
  const diffInSeconds = differenceInSeconds(to, from);

  return diffInSeconds === 86399;
}
