import type { ActionReducerMapBuilder, CreateSliceOptions } from '@reduxjs/toolkit';
import type { GlobalState } from './slice';

import { siteApi } from '../site';

// Reducers for global slice
export const reducers: CreateSliceOptions<GlobalState>['reducers'] = {
  setLoading: (state, action) => {
    state.loading = action.payload;
  },
  toggleDarkMode: (state) => {
    state.darkMode = !state.darkMode;
    localStorage.setItem('theme-dark-mode', state.darkMode.toString());
  },
  setPageTitle: (state, action) => {
    state.pageTitle = action.payload;
  },
};

// Extra reducers for global slice
export const extraReducers: CreateSliceOptions<GlobalState>['extraReducers'] = (builder: ActionReducerMapBuilder<GlobalState>) => {
  return builder
    .addMatcher(siteApi.endpoints.fetchSites.matchPending, (state) => {
      state.loading = true;
    })
    .addMatcher(siteApi.endpoints.fetchSites.matchFulfilled, (state) => {
      state.loading = false;
    });
  // .addMatcher(siteApi.endpoints.fetchSiteReport.matchPending, (state) => {
  //   state.loading = true;
  // })
  // .addMatcher(siteApi.endpoints.fetchSiteReport.matchFulfilled, (state) => {
  //   state.loading = false;
  // })
};
