import type { ActionReducerMapBuilder, CreateSliceOptions } from '@reduxjs/toolkit';
import type { UserState } from './slice';

import { userApi } from './service';
import { initialState } from './slice';

/** Reducers for user slice */
export const reducers: CreateSliceOptions<UserState>['reducers'] = {
  setUser: (state, action) => {
    state = action.payload;
  },
  logout: () => {
    if (window && typeof window !== 'undefined') {
      window.location.href = `/api/auth/logout?success=${btoa('/')}`;

      return initialState;
    }
  },
};

/**
 * Extra reducers for user slice
 *
 * @param builder ActionReducerMapBuilder<UserState>
 */
export const extraReducers: CreateSliceOptions<UserState>['extraReducers'] = (builder: ActionReducerMapBuilder<UserState>) => {
  builder.addMatcher(userApi.endpoints.fetchMe.matchFulfilled, (state, { payload }) => {
    state.id = payload.id;
    state.partner = payload.partner;
    state.name = payload.name;
    state.email = payload.email;
    state.roles = payload.roles;
    state.useMfa = payload.useMfa;
    state.isAuth = true;
  });
};
