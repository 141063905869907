import type { Contact } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

/**
 * Contact RTK Query API
 *
 * @see https://redux-toolkit.js.org/rtk-query/overview
 */
export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['Contacts'],
  endpoints: (builder) => ({
    fetchContacts: builder.query<Contact[], void>({
      query: () => {
        return {
          url: 'api/mezzanine/contacts',
          method: 'GET',
        };
      },
      providesTags: ['Contacts'],
    }),
  }),
});

export const { useFetchContactsQuery, useLazyFetchContactsQuery } = contactApi;
