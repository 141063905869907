import type { FetchReadingsPayload, TimeSeriesResponse } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const chartApi = createApi({
  reducerPath: 'chartApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: [],
  endpoints: (builder) => {
    return {
      fetchSiteReadings: builder.query<TimeSeriesResponse[], FetchReadingsPayload>({
        query: ({ siteId, dateRange }) => {
          return {
            url: `${METERING_API}/site/${siteId}/realized?from=${dateRange?.from}&to=${dateRange?.to}`,
            method: 'GET',
          };
        },
      }),
    };
  },
});

export const METERING_API = 'api/metering';

export const { useFetchSiteReadingsQuery, useLazyFetchSiteReadingsQuery } = chartApi;
