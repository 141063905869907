import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { ContactState } from './slice';

import { processContact } from '../utils';
import { contactApi } from './service';

/** Reducers for contact slice */
export const reducers: CreateSliceOptions<ContactState>['reducers'] = {
  setContactLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
} as const;

/**
 * Extra reducers for contact slice
 *
 * @param builder ActionReducerMapBuilder<ContactState>
 */
export const extraReducers: CreateSliceOptions<ContactState>['extraReducers'] = (builder: ActionReducerMapBuilder<ContactState>) => {
  return builder.addMatcher(contactApi.endpoints.fetchContacts.matchFulfilled, (state, { payload }) => {
    state.contacts = [...state.contacts.map((c) => processContact(c, 'NEXTFLEX')), ...payload.map((c) => processContact(c, 'OTHER'))];
  });
};
