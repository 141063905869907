import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import App from './app/app';
import i18next from './i18n/config';
import { AuthProvider, ConfigProvider, ThemeProvider } from './providers';
import { store } from './store';
import { loadConfig } from './utils';

(async () => {
  try {
    const config = await loadConfig();

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        /^https:\/\/localhost\.(dev|demo|uat)\.kiwi\.res-noprod\.gem\.engie\.com:8080\/?$/,
        /^https:\/\/localhost\.kiwi\.res\.gem\.engie\.com:8080\/?$/,
        /^https:\/\/(dev|demo|uat)\.energylive\.engie\.com\/?$/,
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 1.0,
      environment: config.env,
    });

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(
      <StrictMode>
        <Sentry.ErrorBoundary>
          <ConfigProvider config={config}>
            <StoreProvider store={store}>
              <I18nextProvider i18n={i18next}>
                <ThemeProvider>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </ThemeProvider>
              </I18nextProvider>
            </StoreProvider>
          </ConfigProvider>
        </Sentry.ErrorBoundary>
      </StrictMode>
    );
  } catch (error) {
    console.error('Failed to load configuration:', error);
  }
})();
