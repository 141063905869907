import type { RootState } from '../types';

import React from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { DefaultLayout, Spinner } from '../components';
import { routes } from '../router';
import { hasPermission } from '../utils';

export const App = () => {
  const roles = useSelector((state: RootState) => state.user?.roles);

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route path="/" element={<DefaultLayout />}>
          {routes
            .filter((route) => hasPermission(roles ?? [], route.can))
            .map((route) => {
              return <Route key={route.path} path={route.path} lazy={route.component} index={route.index} />;
            })}
        </Route>
        <Route path="*" element={<div>Not found</div>} />
      </React.Fragment>
    )
  );

  return <RouterProvider router={router} fallbackElement={<Spinner />} />;
};

export default Sentry.withProfiler(App);
