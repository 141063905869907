import { REPORT_PDF } from '../store';

const triggerDownload = (url: string, filename: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.textContent = 'download';
  a.download = filename;
  a.href = url;
  a.click();
  a.remove();

  setTimeout(() => window.URL.revokeObjectURL(url), 100);
};

export const downloadBlob = (content: Blob, filename: string) => {
  const url = URL.createObjectURL(content);

  triggerDownload(url, filename);
};

export const downloadFile = (url: string) => {
  triggerDownload(url, url.substring(url.lastIndexOf('/') + 1));
};

export const downloadReport = (filename: string) => {
  downloadFile(`${REPORT_PDF}${filename}`);
};
