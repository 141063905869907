import type { Route } from '../../types';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NJSidebar, NJSidebarBrand, NJSidebarClose, NJSidebarItem, NJSidebarMenu } from '@engie-group/fluid-design-system-react';

import { useOutsideClick } from '../../hooks';
import { userSelector } from '../../store';
import { hasPermission, isRouteActive } from '../../utils';
import styles from './Sidebar.module.scss';

interface SidebarProps {
  items: Route[];
}

export const Sidebar: React.FC<SidebarProps> = ({ items }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const roles = useSelector(userSelector).roles;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('common');

  const [isFolded, setIsFolded] = React.useState(true);

  useOutsideClick(ref, () => {
    setIsFolded(true);
  });

  return (
    <NJSidebar ref={ref} isFolded={isFolded}>
      <NJSidebarBrand />
      <NJSidebarMenu className={styles['sidebar']} {...{ id: 'test' }}>
        {items
          .filter((item) => hasPermission(roles ?? [], item.can))
          .map((item) => {
            return (
              <NJSidebarItem
                key={item.label}
                icon={item.icon}
                onClickItem={() => navigate(item.path)}
                isActive={isRouteActive(location, item.path)}
                className="text-gray-100"
              >
                {item.label !== undefined && t(item.label)}
              </NJSidebarItem>
            );
          })}
      </NJSidebarMenu>
      <NJSidebarMenu className="overflow-hidden text-gray-light-100" isFooterNav>
        <NJSidebarClose onClick={() => setIsFolded(!isFolded)}>
          <span>{t('sidebar.close-button')}</span>
        </NJSidebarClose>
      </NJSidebarMenu>
    </NJSidebar>
  );
};
