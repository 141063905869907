import type { HighchartsReactRefObject } from 'highcharts-react-official';
import type { ActivationId, SiteId, TimeSeriesResponse } from '../../types';

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addMinutes, fromUnixTime, subMinutes } from 'date-fns';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { merge } from 'lodash';

import { chartSelector, setChartLoading, siteActivations, siteSelector, useLazyFetchSiteReadingsQuery } from '../../store';
import { Chart } from './Chart';
import { exportingOptions, lightTheme, readingsChartConfig, seriesConfig } from './Chart.config';

NoDataToDisplay(Highcharts);

const TIME_PAD = 100;

export type ActivationChartProps = {
  activationId?: ActivationId;
  siteId?: SiteId;
};

export interface ActivationChartExportHandler {
  exportChart: () => void;
  chartRef: HighchartsReactRefObject | null;
}

export const ActivationChart = forwardRef<ActivationChartExportHandler, ActivationChartProps>(({ activationId, siteId }, ref) => {
  const chartRef = useRef<HighchartsReactRefObject>(null);

  const { t } = useTranslation(['activation']);

  const dispatch = useDispatch();
  const chartStore = useSelector(chartSelector);
  const siteStore = useSelector(siteSelector);
  const getSiteActivations = useSelector(siteActivations);

  const [triggerFetchSiteReadings] = useLazyFetchSiteReadingsQuery();

  const [highchartsOptions, setHighchartsOptions] = useState<Highcharts.Options>(
    merge(lightTheme, readingsChartConfig, {
      title: {
        text: t('chart.title'),
      },
      xAxis: {
        title: {
          text: t('chart.time'),
        },
      },
      yAxis: {
        title: {
          text: `${t('chart.power-delivered')} (kWh)`,
        },
      },

      lang: {
        noData: t('chart.no-data'),
      },
    })
  );

  const setChartData = (data: TimeSeriesResponse[]) => {
    setHighchartsOptions({
      series: [
        {
          ...seriesConfig,
          data: data.map((series) => ({
            x: fromUnixTime(series.timestamp).getTime(),
            y: series.reading,
          })),
        },
      ],
    });
  };

  useEffect(() => {
    if (activationId) {
      const activation = getSiteActivations.find((activation) => activation.activationId === activationId);
      dispatch(setChartLoading(true));

      if (!activation) {
        return;
      }

      triggerFetchSiteReadings(
        {
          siteId: siteId,
          dateRange: {
            from: subMinutes(new Date(activation.from), TIME_PAD).toISOString(), // Not fully sure the date is correct
            to: addMinutes(new Date(activation.to), TIME_PAD).toISOString(), // Not fully sure the date is correct
          },
        },
        false
      )
        .then((data) => {
          if (data.isSuccess) {
            setChartData(data.data);
          }
        })
        .finally(() => {
          dispatch(setChartLoading(false));
        });
    } else {
      setChartData([]);
      dispatch(setChartLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationId]);

  useImperativeHandle(ref, () => ({
    exportChart() {
      chartRef.current?.chart.exportChartLocal(exportingOptions, highchartsOptions);
    },
    get chartRef() {
      return chartRef.current;
    },
  }));

  return <Chart chartRef={chartRef} loading={chartStore.loading} options={highchartsOptions} />;
});
