import type { User } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface UserState extends User {
  isAuth: boolean;
}

export const initialState: UserState = {
  isAuth: false,
  id: null,
  partner: null,
  name: null,
  email: null,
  roles: null,
  useMfa: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers,
  extraReducers,
});

export const { setUser, logout } = userSlice.actions;

export const userSelector = (state: { user: UserState }) => state.user;
export const userReducer = userSlice.reducer;
