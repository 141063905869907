import type { HighchartsReactRefObject } from 'highcharts-react-official';

import React from 'react';

import { NJSpinner } from '@engie-group/fluid-design-system-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting';

if (typeof Highcharts === 'object') {
  highchartsAccessibility(Highcharts);
  highchartsExporting(Highcharts);
  highchartsOfflineExporting(Highcharts);
}

export type ChartProps = {
  // Highcharts Options
  options?: Highcharts.Options;
  // Chart Loading
  loading?: boolean;
  // Chart Ref
  chartRef: React.RefObject<HighchartsReactRefObject>;
};

export const Chart: React.FC<ChartProps> = ({ options, loading = true, chartRef }) => {
  return (
    <div data-testid="chart-container" className="mh-100 h-100 position-relative">
      {loading ? (
        <div data-testid="chart-loading" className="position-absolute z-1 w-full h-100 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-fill justify-content-center">
            <NJSpinner size="md" isLoading />
          </div>
        </div>
      ) : null}
      <div data-testid="chart" className="h-100 position-relative">
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} containerProps={{ style: { height: '100%' } }} />
      </div>
    </div>
  );
};
