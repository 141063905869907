import type { ActivationId, DispatchEvent } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface ActivationState {
  loading: boolean;
  activationIds: ActivationId[];
  dispatchEvents: DispatchEvent[];
}

const initialState: ActivationState = {
  loading: false,
  activationIds: [],
  dispatchEvents: [],
};

export const activationSlice = createSlice({
  name: 'activation',
  initialState,
  reducers,
  extraReducers,
});

export const activationSelector = (state: { activation: ActivationState }) => state.activation;
export const activationReducer = activationSlice.reducer;

export const { setActivationsLoading } = activationSlice.actions;
