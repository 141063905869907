import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { ChartState } from './slice';

export const reducers: CreateSliceOptions<ChartState>['reducers'] = {
  setChartLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
};

export const extraReducers: CreateSliceOptions<ChartState>['extraReducers'] = (builder: ActionReducerMapBuilder<ChartState>) => {
  return builder;
};
