import type { User } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

/**
 * User RTK Query API
 * @see https://redux-toolkit.js.org/rtk-query/overview
 */
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/auth/v2',
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    fetchMe: builder.query<User, void>({
      query: () => {
        return {
          url: '/me',
          method: 'GET',
        };
      },
      providesTags: ['User'],
    }),
  }),
});

export const { useFetchMeQuery, useLazyFetchMeQuery } = userApi;
