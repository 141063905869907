import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { ActivationState } from './slice';

import { activationApi } from './service';

/** Reducers for activation slice */
export const reducers: CreateSliceOptions<ActivationState>['reducers'] = {
  setActivationsLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
} as const;

/**
 * Extra reducers for site slice
 *
 * @param builder ActionReducerMapBuilder<ActivationState>
 */
export const extraReducers: CreateSliceOptions<ActivationState>['extraReducers'] = (builder: ActionReducerMapBuilder<ActivationState>) => {
  return builder
    .addMatcher(activationApi.endpoints.fetchActivationIds.matchFulfilled, (state, { payload }) => {
      state.activationIds = payload;
    })
    .addMatcher(activationApi.endpoints.fetchActivation.matchFulfilled, (state, { payload }) => {
      state.dispatchEvents.push(payload);
    });
};
