import type { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';

import DatePickerLib from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NJFormItem, NJIconButton } from '@engie-group/fluid-design-system-react';
import { getMonth, getYear } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { range } from 'lodash';

import { getMonthNames } from '../../utils';

type DatePickerProps = ReactDatePickerProps & {
  // Id of the date picker
  id: string;
  // Label that should be displayed above the date picker
  label?: string;
  // Icon name that should be displayed next to the input
  iconName?: string;
  // Display custom header
  isCustomHeader?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({ id, label, iconName, isCustomHeader, required, showTimeSelect, disabled, ...props }) => {
  const { i18n, t } = useTranslation('common');

  const dateSelectFormat = i18n.language === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  const timeSelectFormat = i18n.language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy h:mm aa';

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    prevMonthButtonDisabled,
    changeYear,
    changeMonth,
    increaseMonth,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps): React.ReactNode => {
    const years = range(2010, getYear(new Date()) + 1, 1);
    const months = getMonthNames(i18n.language === 'fr' ? fr : enGB);

    return (
      <div className="d-flex justify-content-between align-items-center px-1">
        <NJIconButton icon="navigate_before" label="Navigate before" onClick={decreaseMonth} isDisabled={prevMonthButtonDisabled} />
        <select data-testid="DatePickerYearSelect" value={getYear(date)} onChange={({ target: { value } }) => changeYear(Number(value))}>
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          data-testid="DatePickerMonthSelect"
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <NJIconButton icon="navigate_next" label="Navigate after" onClick={increaseMonth} isDisabled={nextMonthButtonDisabled} />
      </div>
    );
  };

  return (
    <DatePickerLib
      {...props}
      locale={i18n.language === 'fr' ? fr : enGB}
      dateFormat={showTimeSelect ? timeSelectFormat : dateSelectFormat}
      customInput={<CustomNJFormItem id={id} label={label} iconName={iconName} isRequired={required} isDisabled={disabled} />}
      renderCustomHeader={isCustomHeader ? renderCustomHeader : undefined}
      calendarClassName="border-1 rounded-0 z-300"
      showPopperArrow={false}
      timeCaption={t('inputs.time')}
      timeFormat="p"
      showTimeSelect={showTimeSelect}
      disabled={disabled}
    />
  );
};

type CustomNJFormItemProps = {
  // ID of the form item
  id: string;
  // Label that should be displayed above the form item
  label?: string;
  // Icon name that should be deplay at the end of the input
  iconName?: string;
  // Value that should be displayed in the input
  value?: string;
  // Function that should be called when the input is clicked
  onClick?: () => void;
  // Is the input required
  isRequired?: boolean;
  // Is the input disabled
  isDisabled?: boolean;
};

const CustomNJFormItem = forwardRef<HTMLDivElement, CustomNJFormItemProps>(({ id, label, iconName, value, onClick, isRequired, isDisabled }, ref) => (
  <NJFormItem
    id={id}
    label={label || ''}
    labelKind="static"
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly
    iconName={iconName}
    iconClick={onClick}
    isRequired={isRequired}
    isDisabled={isDisabled}
  />
));
