import type { Contract } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface ContractState {
  loading: boolean;
  contracts: Contract[];
}

const initialState: ContractState = {
  loading: false,
  contracts: [],
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers,
  extraReducers,
});

export const contractStore = (state: { contract: ContractState }) => state.contract;
export const contractReducer = contractSlice.reducer;

export const { setContractsLoading } = contractSlice.actions;
