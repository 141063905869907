import { createSlice } from '@reduxjs/toolkit';

import { checkDarkMode } from '../../utils';
import { extraReducers, reducers } from './reducer';

export interface GlobalState {
  loading: boolean;
  darkMode: boolean;
  pageTitle: string;
}

const initialState: GlobalState = {
  loading: false,
  darkMode: checkDarkMode(),
  pageTitle: '',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers,
  extraReducers,
});

export const globalSelector = (state: { global: GlobalState }) => state.global;
export const globalReducer = globalSlice.reducer;
export const { setPageTitle, toggleDarkMode } = globalSlice.actions;
