import type { Contact, EarningsStatement } from '../types';

import parsePhoneNumberFromString from 'libphonenumber-js';

/**
 * Calculate the initial earning of an earning statement
 * The initial earning is the total earning minus VAT (20%)
 * @param earning {EarningsStatement}
 * @returns {number}
 */
export const calculateInitialEarnings = (earning: EarningsStatement): number => {
  return earning.initial_income;
};

/**
 * Calculate the penalty of an earning statement
 * @param earning {EarningsStatement}
 * @returns {number}
 */
export const calculatePenalty = (earning: EarningsStatement): number => {
  return earning.penalty_amount + earning.gc_penalty_amount;
};

/**
 * Calculate the total earning of an earning statement
 * @param earning {EarningsStatement}
 * @returns {number}
 */
export const calculateTotalEarnings = (earning: EarningsStatement): number => {
  return earning.total;
};

/**
 * Process a contact by formatting the phone number and setting the type
 * @param contact {Contact}
 * @param type {Contact['type']}
 * @returns {Contact}
 */
export const processContact = (contact: Contact, type: Contact['type']): Contact => {
  if (!contact.phoneNumber) {
    return contact;
  }

  const phoneNumber = parsePhoneNumberFromString(contact.phoneNumber);

  contact.phoneNumber = phoneNumber ? phoneNumber.formatInternational() : contact.phoneNumber;
  contact.type = type;

  return contact;
};
