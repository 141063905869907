import type { PropsWithChildren } from 'react';
import type { RootState } from '../types';

import React from 'react';
import { useSelector } from 'react-redux';

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const darkMode = useSelector((state: RootState) => state.global.darkMode);

  React.useLayoutEffect(() => {
    if (window && typeof window !== 'undefined') {
      const body = window.document.body;
      body.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    }
  }, [darkMode]);

  return children;
};
