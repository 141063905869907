import type { Contract, DocumentBundlePayload, DocumentPayload } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { t } from 'i18next';

import { downloadBlob } from '../../utils/download-file';

/**
 * Contract RTK Query API
 */
export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['Contracts'],
  endpoints: (builder) => {
    return {
      fetchContracts: builder.query<Contract[], void>({
        query: () => {
          return {
            url: `${DOCUMENT_API}`,
            method: 'GET',
          };
        },
        providesTags: ['Contracts'],
      }),
      fetchDocument: builder.query<string, DocumentPayload>({
        query: (payload) => {
          return {
            url: generateDocumentUrl(payload),
            method: 'GET',
            responseHandler: async (response) => {
              return URL.createObjectURL(await response.blob());
            },
            cache: 'no-cache',
          };
        },
      }),
      fetchDocumentBundle: builder.mutation<Response, DocumentBundlePayload>({
        query: (payload) => {
          const fileName = t('contracts:bundle-filename');
          return {
            url: `${DOCUMENT_API}/bundle`,
            method: 'POST',
            body: {
              fileName,
              documents: payload,
            },
            responseHandler: async (response) => {
              downloadBlob(await response.blob(), fileName);
              return response;
            },
            cache: 'no-cache',
          };
        },
      }),
    };
  },
});

export const generateDocumentUrl = ({ type, year, name }: DocumentPayload) => `${DOCUMENT_API}/${type}/${year}/${name}`;

export const DOCUMENT_API = 'api/mezzanine/contract-docs';

export const { useFetchContractsQuery, useLazyFetchDocumentQuery, useFetchDocumentBundleMutation } = contractApi;
