import type { ActivationEvent, ActivationStages, RootState, SiteActivation, SiteId } from '../../types';

import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'date-fns';
import { partition } from 'lodash';

import { dateTimeFormatter, durationFormatter } from '../../utils';
import { dataSite, siteReportsBySitesAndYear, sortedSites } from '../site';

// OK
export const activationData = (state: RootState) => state.activation;

// OK - tested
export const siteActivations = createSelector([siteReportsBySitesAndYear], (reports) => {
  return reports
    .flatMap((report) => report.performance_reports)
    .sort((a, b) => {
      const dateA = new Date(a.start_datetime).setHours(0, 0, 0, 0);
      const dateB = new Date(b.start_datetime).setHours(0, 0, 0, 0);
      if (dateA === dateB) {
        const timeA = new Date(a.start_datetime).getTime();
        const timeB = new Date(b.start_datetime).getTime();
        return timeA - timeB;
      }
      return dateB - dateA;
    })
    .map(
      (performanceReport): SiteActivation => ({
        activationId: performanceReport.activation_ref,
        status: performanceReport.status,
        duration: durationFormatter({
          from: performanceReport.start_datetime,
          to: performanceReport.end_datetime,
        }),
        date: dateTimeFormatter({ timestamp: new Date(performanceReport.start_datetime), format: 'DATE' }),
        power: performanceReport.delivered_energy_kwh,
        report: performanceReport.file_name,
        from: performanceReport.start_datetime,
        to: performanceReport.end_datetime,
      })
    );
});

// OK - tested
export const siteActivationSummary = createSelector([dataSite, sortedSites, siteReportsBySitesAndYear], (data, sites, reports) => {
  return sites
    .filter((site) => data.selectedSites.includes(site.siteId) || data.selectedSites.length === 0)
    .map((site) => {
      const activations = reports
        .filter((report) => report.site_identifier === site.siteId)
        .flatMap((report) => report.performance_reports)
        .map((performanceReport) => ({
          start: new Date(performanceReport.start_datetime),
          end: new Date(performanceReport.end_datetime),
          status: performanceReport.status,
          filename: performanceReport.file_name,
        }));

      const [ok, ko] = partition(activations, (activation) => activation.status === 'SUCCESSFUL');

      const lastActivation =
        activations.length &&
        activations.reduce((a, b) => {
          const rangeA = a.end.getTime() - a.start.getTime();
          const rangeB = b.end.getTime() - b.start.getTime();
          return rangeA < rangeB ? a : b;
        });

      return {
        siteId: site.siteId,
        company: site.flexibilitySupplierName ?? '',
        siteName: site.siteName ?? '',
        lastActivation: lastActivation === 0 ? 'N/A' : lastActivation.status,
        prm: site?.prm,
        ok: ok.length,
        ko: ko.length,
        filenames: activations.map((activation) => activation.filename),
      };
    });
});

// OK
export const siteActivationEvents = createSelector(
  [activationData, siteReportsBySitesAndYear, (_: RootState, siteId: SiteId) => siteId],
  (data, reports, siteId) =>
    reports
      .filter((site) => site.site_identifier === siteId)
      .flatMap((report) => report.performance_reports)
      .sort((a, b) => {
        const dateA = new Date(a.start_datetime).setHours(0, 0, 0, 0);
        const dateB = new Date(b.start_datetime).setHours(0, 0, 0, 0);
        if (dateA === dateB) {
          const timeA = new Date(a.start_datetime).getTime();
          const timeB = new Date(b.start_datetime).getTime();
          return timeA - timeB;
        }
        return dateB - dateA;
      })
      .map((report): ActivationEvent => {
        const timetable = data.dispatchEvents
          .filter(
            (dispatchEvent) =>
              isEqual(new Date(report.start_datetime), new Date(dispatchEvent.dispatchTime).getTime()) &&
              Object.values(dispatchEvent.bucketNameToSiteTimetables)
                .flat()
                .find((siteTimetable) => siteTimetable.site.id.toString() === siteId)
          )
          .map((dispatchEvent): ActivationStages[] => {
            return Object.values(dispatchEvent.bucketNameToSiteTimetables)
              .flat()
              .map((siteTimetable) => ({
                ...siteTimetable.timetable
                  .map((stage) => ({
                    [stage.type]: stage.planned,
                  }))
                  .reduce((acc, cur) => {
                    return { ...acc, ...cur };
                  }, {}),
              }));
          })
          .flat();

        return {
          activationId: report.activation_ref,
          status: report.status,
          siteId,
          date: new Date(report.start_datetime),
          duration: durationFormatter({
            from: report.start_datetime,
            to: report.end_datetime,
          }),
          reportFilename: report.file_name,
          energyDelivered: report.delivered_energy_kwh,
          ...timetable[0],
          StartNotification: report.start_datetime,
          StopNotification: report.end_datetime,
        };
      })
);
