import type { ActivationId, DispatchEvent, DispatchEventLite, FetchActivationsPayload } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

/**
 * Activation RTK Query API
 */
export const activationApi = createApi({
  reducerPath: 'activationApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['ActivationIds', 'DispatchEventDetail'],
  endpoints: (builder) => {
    return {
      fetchActivationIds: builder.query<ActivationId[], FetchActivationsPayload>({
        query: ({ siteIds, from, to }) => {
          return {
            url: `${ACTIVATION_API}/dispatches?siteIds=${siteIds.join(',')}&from=${from.toISOString()}&to=${to.toISOString()}`,
            method: 'GET',
          };
        },
        transformResponse: (response: DispatchEventLite[]) => {
          return response.map((dispatchEvent) => dispatchEvent.dispatchId);
        },
        providesTags: ['ActivationIds'],
      }),
      fetchActivation: builder.query<DispatchEvent, ActivationId>({
        query: (activationId) => {
          return {
            url: `${ACTIVATION_API}/dispatch/${activationId}`,
            method: 'GET',
          };
        },
        providesTags: ['DispatchEventDetail'],
      }),
    };
  },
});

export const ACTIVATION_API = 'api/lintel/v2';

export const { useFetchActivationIdsQuery, useLazyFetchActivationQuery } = activationApi;
