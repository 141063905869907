import type { PropsWithChildren } from 'react';
import type { RootState } from '../types';

import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from '../components';
import { useFetchMeQuery } from '../store';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, error, isLoading, isFetching } = useFetchMeQuery();
  const isAuth = useSelector((state: RootState) => state.user.isAuth);

  React.useEffect(() => {
    if ((!isLoading || !isFetching) && error && !data && !isAuth) {
      if (window && typeof window !== 'undefined') {
        const developmentUrl = '/login?success=Lw';
        const productionUrl = '/oauth/okta';

        const redirectUrl = process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl;
        window.location.href = redirectUrl;
      }
    }
  }, [data, error, isAuth, isFetching, isLoading]);

  if (isLoading || isFetching || !isAuth) {
    return <Spinner />;
  }

  return children;
};
