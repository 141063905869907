import type { Contact } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { NextflexContacts } from './data';
import { extraReducers, reducers } from './reducer';

export interface ContactState {
  loading: boolean;
  contacts: Contact[];
}

const initialState: ContactState = {
  loading: false,
  contacts: [...NextflexContacts],
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers,
  extraReducers,
});

export const contactSelector = (state: { contact: ContactState }) => state.contact;
export const contactReducer = contactSlice.reducer;
export const contactActions = contactSlice.actions;

export const { setContactLoading } = contactSlice.actions as (typeof contactSlice)['actions'];
