import type { Action, Role } from '../types';

import { permissionMap } from '../acl';

/**
 * Helper function to check if user has permission to perform an action
 * @param role Role[]
 * @param action Action
 * @returns Boolean
 */
export const hasPermission = (roles: Role[], action: Action) => {
  if (!roles.length) return false;

  return roles.some((role) => {
    return permissionMap.get(action)?.includes(role);
  });
};
