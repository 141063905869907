import type { PerformanceReport, RootState, Site, SiteId, SiteReport } from '../../types';
import type { SiteState } from './slice';

import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import _ from 'lodash';

import { calculateInitialEarnings, calculatePenalty, calculateTotalEarnings } from '../utils';

const siteOldData = (state: SiteState) => state;

// OK
export const dataSite = (state: RootState) => state.site;

// OK - tested
export const arrayOfYears = createSelector([dataSite], (data) => {
  if (!data.selectedSite) return _.orderBy(_.uniq(data.sites.flatMap((site) => site.contractedYears || [])), [], ['desc']);
  const years = data.sites.find((site) => site.siteId === data.selectedSite)?.contractedYears || [];
  return _.orderBy(years, [], ['desc']);
});

// OK - tested
export const sortedSites = createSelector([dataSite], (data) => {
  const filteredSites = data.selectedYear ? data.sites.filter((site) => site.contractedYears.includes(data.selectedYear.toString())) : data.sites;
  return _.sortBy<Site>(filteredSites, ['siteName']);
});

// OK - tested
export const contactSortedSites: (state: RootState) => Site[] = createSelector([dataSite], (data) => {
  return _.sortBy<Site>(data?.sites.filter((site) => site.contractedYears.includes(new Date().getFullYear().toString())), ['siteName']);
});

// OK - tested
export const siteReportsBySitesAndYear = createSelector([dataSite], (data) => {
  return (
    data?.siteReports
      .filter((siteReport) => data.selectedSites.includes(siteReport.site_identifier) || data.selectedSites.length === 0)
      .filter((siteReport) => data.selectedSite === siteReport.site_identifier || !data.selectedSite) || []
  );
});

// OK - tested
export const siteSynthesis = createSelector([siteReportsBySitesAndYear], (siteReports) => {
  const activations = siteReports.flatMap((siteReport) => siteReport.performance_reports);
  const splitActivations = _.partition(activations, (activation) => activation.status === 'SUCCESSFUL');

  const summary = siteReports
    .flatMap((siteReport) => siteReport.earning_reports)
    .reduce(
      (sum, curr) => {
        const initialEarning = calculateInitialEarnings(curr);
        const totalPenalty = calculatePenalty(curr);
        const totalEarning = calculateTotalEarnings(curr);

        return {
          totalEnergyOrDuration: sum.totalEnergyOrDuration + curr.total_energy_or_duration,
          totalInitialEarning: sum.totalInitialEarning + initialEarning,
          totalPenalty: sum.totalPenalty + totalPenalty,
          totalEarning: sum.totalEarning + totalEarning,
          totalVariableCompensation: sum.totalVariableCompensation + curr.activation_rem_amount,
        };
      },
      {
        totalEarning: 0,
        totalInitialEarning: 0,
        totalPenalty: 0,
        totalEnergyOrDuration: 0,
        totalVariableCompensation: 0,
      }
    );

  const ok = Math.round((splitActivations[0].length / activations.length) * 100) / 100 || 0;
  const ko = Math.round((splitActivations[1].length / activations.length) * 100) / 100 || 0;

  return {
    ...summary,
    totalActivation: activations.length,
    ok,
    ko,
  };
});

// OK - tested
export const sitesUnavailabilities = createSelector([dataSite], (data) => {
  if (!data || data.sitesUnavailabilities.length === 0) return 0;

  const filteredSitesUnavailabilities = data?.sitesUnavailabilities.filter(
    (site) => data.selectedSites.includes(site.siteId) || data.selectedSites.length === 0
  );

  const availabilityRates = filteredSitesUnavailabilities?.map((site) => site.availabilityRate);
  const averageAvailabilityRate = availabilityRates?.reduce((acc, rate) => acc + rate, 0) / availabilityRates?.length;

  return averageAvailabilityRate;
});

// OK - tested
export const synthesisFilteredBySite = createSelector([dataSite, sortedSites, siteReportsBySitesAndYear], (data, sites, reports) => {
  const dataToReturn = sites
    .filter((site) => data.selectedSites.includes(site.siteId) || data.selectedSites.length === 0)
    .map((site) => {
      const activations = reports
        .filter((report) => report.site_identifier === site.siteId)
        .flatMap((report) => report.performance_reports)
        .sort((a, b) => {
          const dateA = new Date(a.start_datetime).setHours(0, 0, 0, 0);
          const dateB = new Date(b.start_datetime).setHours(0, 0, 0, 0);
          if (dateA === dateB) {
            const timeA = new Date(a.start_datetime).getTime();
            const timeB = new Date(b.start_datetime).getTime();
            return timeA - timeB;
          }
          return dateB - dateA;
        })
        .map((performanceReport) => ({
          start: new Date(performanceReport.start_datetime),
          end: new Date(performanceReport.end_datetime),
          status: performanceReport.status,
        }));

      const splitActivations = _.partition(activations, (activation) => activation.status === 'SUCCESSFUL');

      const lastActivation =
        activations.length &&
        activations.reduce((a, b) => {
          return a.start > b.start ? b : a;
        });

      const earnings = reports
        .filter((report) => report.site_identifier === site.siteId)
        .flatMap((report) => report.earning_reports)
        .reduce(
          (sum, curr) => {
            const initialEarning = calculateInitialEarnings(curr);

            return {
              totalInitialEarning: sum.totalInitialEarning + initialEarning,
            };
          },
          {
            totalInitialEarning: 0,
          }
        );

      return {
        id: site.siteId,
        company: site.flexibilitySupplierName,
        site: site.siteName,
        totalInitialEarning: earnings.totalInitialEarning,
        availability: site.dispatchable,
        lastActivation: lastActivation === 0 ? 'N/A' : lastActivation.status,
        ok: splitActivations[0].length / activations.length || 0,
        ko: splitActivations[1].length / activations.length || 0,
      };
    });

  return dataToReturn;
});

// OK - tested
export const siteUnavailabilitiesBySiteId = createSelector([dataSite], (data) => {
  return data.sitesUnavailabilities.find((site) => site.siteId === data.selectedSite)?.availabilityRate;
});

// ________________________________________________________

export const filteredSites = createSelector([siteOldData], (data) => {
  if (data.selectedSites?.length === 0) return data.sites.filter((site) => site.contractedYears.includes(site.siteId));

  return data.sites?.filter((site) => site.contractedYears.includes(site.siteId) && data.selectedSites.includes(site.siteId));
});

const isForSelectedYear = (date: string, selectedYear: number) => format(new Date(date), 'yyyy') === selectedYear.toString();

export const siteReportsForSelectedSitesAndYear = createSelector([siteOldData], (data) => {
  return data.siteReport
    .filter((siteReport) => data.selectedSites.includes(siteReport.siteId) || data.selectedSites.length === 0)
    .filter((siteReport) => (data.selectedSite && data.selectedSite === siteReport.siteId) || !data.selectedSite)
    .map((siteReport): SiteReport => {
      return {
        siteId: siteReport.siteId,
        earning_reports: siteReport.earning_reports.filter((earningReport) => isForSelectedYear(earningReport.end_datetime, data.selectedYear)),
        performance_reports: siteReport.performance_reports.filter((performanceReport) =>
          isForSelectedYear(performanceReport.end_datetime, data.selectedYear)
        ),
      };
    });
});

export const performanceReportsForSelectedSiteAndYear = createSelector(
  [siteReportsForSelectedSitesAndYear, (_, siteId: SiteId) => siteId],
  (filteredSiteReports, siteId) => {
    return filteredSiteReports.filter((siteReport) => siteId === siteReport.siteId).flatMap((siteReport) => siteReport.performance_reports);
  }
);

export const earningReportsForSelectedSitesAndYear = createSelector([siteReportsForSelectedSitesAndYear], (filteredSiteReports) => {
  return filteredSiteReports.flatMap((siteReport) => siteReport.earning_reports);
});

export const earningReportsForSingleSite = createSelector(
  [siteReportsForSelectedSitesAndYear, (_: SiteState, siteId: SiteId) => siteId],
  (filteredSiteReports, siteId) =>
    filteredSiteReports.filter((siteReport) => siteId === siteReport.siteId).flatMap((siteReport) => siteReport.earning_reports)
);

export const siteActivationRemunerationPerPr = createSelector(
  [
    siteReportsBySitesAndYear,
    (_: RootState, siteId: string) => siteId,
    (_: RootState, siteId: string, selectedFileName?: string) => selectedFileName,
  ],

  (siteReports, siteId, selectedFileName) => {
    return selectedFileName
      ? siteReports
          .find((r) => {
            return r.site_identifier === siteId;
          })
          ?.earning_reports.find((report) => {
            return selectedFileName in report.activation_rem_per_pr;
          })?.activation_rem_per_pr[selectedFileName] || 0
      : 0;
  }
);

export const siteSynthesisOld = createSelector([siteOldData, siteReportsForSelectedSitesAndYear], (data, filteredSiteReports) => {
  const activations = filteredSiteReports.flatMap((siteReport) => siteReport.performance_reports);
  const splitActivations = _.partition(activations, (activation) => activation.status === 'SUCCESSFUL');

  const summary = filteredSiteReports
    .flatMap((siteReport) => siteReport.earning_reports)
    .reduce(
      (sum, curr) => {
        const initialEarning = curr.global_rem_amount + curr.rr_rem_amount + curr.gc_rem_amount + curr.aoe_rem_amount;
        const totalPenalty = curr.penalty_amount + curr.gc_penalty_amount;

        return {
          totalEnergyOrDuration: sum.totalEnergyOrDuration + curr.total_energy_or_duration,
          totalInitialEarning: sum.totalInitialEarning + initialEarning,
          totalPenalty: sum.totalPenalty + totalPenalty,
          totalEarning: sum.totalEarning + initialEarning + (curr.activation_rem_amount - totalPenalty),
          totalVariableCompensation: sum.totalVariableCompensation + curr.activation_rem_amount,
        };
      },
      {
        totalEarning: 0,
        totalInitialEarning: 0,
        totalPenalty: 0,
        totalEnergyOrDuration: 0,
        totalVariableCompensation: 0,
      }
    );

  return {
    ...summary,
    totalActivation: activations.reduce(
      (unique, item) => (unique.find((report) => report.activation_ref === item.activation_ref) ? unique : [...unique, item]),
      new Array<PerformanceReport>()
    ).length,
    ok: splitActivations[0].length / activations.length || 0,
    ko: splitActivations[1].length / activations.length || 0,
  };
});

export const synthesisFilteredBySiteOld = createSelector([filteredSites, siteReportsForSelectedSitesAndYear], (filteredSite, filteredSiteReports) => {
  const dataToReturn = filteredSite.map((site) => {
    const activations = filteredSiteReports
      .filter((siteReport) => siteReport.siteId === site.siteId)
      .flatMap((siteReport) => siteReport.performance_reports)
      .map((performanceReport) => ({
        start: new Date(performanceReport.start_datetime),
        end: new Date(performanceReport.end_datetime),
        status: performanceReport.status,
      }));

    const splitActivations = _.partition(activations, (activation) => activation.status === 'SUCCESSFUL');

    const lastActivation =
      activations.length &&
      activations.reduce((a, b) => {
        return a.start > b.start ? a : b;
      });

    const earnings = filteredSiteReports
      .filter((siteReport) => siteReport.siteId === site.siteId)
      .flatMap((siteReport) => siteReport.earning_reports)
      .reduce(
        (sum, curr) => {
          const initialEarning = curr.global_rem_amount + curr.rr_rem_amount + curr.gc_rem_amount + curr.aoe_rem_amount;

          return {
            totalInitialEarning: sum.totalInitialEarning + initialEarning,
          };
        },
        {
          totalInitialEarning: 0,
        }
      );

    return {
      id: site.siteId,
      company: site.flexibilitySupplierName,
      site: site.siteName,
      totalInitialEarning: earnings.totalInitialEarning,
      totalRevenueToDate: 0,
      availability: site.dispatchable,
      lastActivation: lastActivation === 0 ? 'N/A' : lastActivation.status,
      ok: splitActivations[0].length / activations.length || 0,
      ko: splitActivations[1].length / activations.length || 0,
    };
  });

  return dataToReturn;
});

export type SynthesisFilteredBySite = {
  id: string;
  company?: string;
  site: string;
  totalInitialEarning: number;
  availability: boolean;
  lastActivation: string;
  ok: number;
  ko: number;
};
