import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { NJSidebarContent } from '@engie-group/fluid-design-system-react';
import * as Sentry from '@sentry/react';

import { globalSelector, useFetchSitesQuery, userSelector } from '../../../store';
import { Header } from '../../header';
import { Sidebar, sideBarItems } from '../../sidebar';
import { Spinner } from '../../spinner';
import styles from './DefaultLayout.module.scss';

export const DefaultLayout = () => {
  const isLoading = useSelector(globalSelector).loading;
  const user = useSelector(userSelector);

  useFetchSitesQuery();

  useEffect(() => {
    if (user.id && user.email) {
      Sentry.setUser({ id: user.id, username: user.email });
    }
  }, [user]);

  return (
    <div className={styles['default-layout']}>
      <Sidebar items={sideBarItems} />
      <NJSidebarContent isAboveContent>
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Header />
            <main className="container-fluid px-2" data-testid="default-layout-main">
              <Outlet />
            </main>
          </React.Fragment>
        )}
      </NJSidebarContent>
    </div>
  );
};
