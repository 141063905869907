import type { CalendarSummary, CalendarUnavailability } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { format, formatISO } from 'date-fns';

export type FetchCalendarSummaryArgs = {
  from: string;
  to: string;
  siteIds?: string;
};

export type FetchUnavailabilityArgs = {
  from: string;
  to: string;
};

export type PostCalendarUnavailabilityArgs = {
  siteId: string;
  start: string;
  end: string;
};

/**
 * Calendar RTK Query API
 *
 * @see https://redux-toolkit.js.org/rtk-query/overview
 */
export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['CalendarSummary', 'CalendarUnavailability'],
  endpoints: (builder) => ({
    fetchCalendarSummary: builder.query<CalendarSummary[], FetchCalendarSummaryArgs>({
      query: (arg) => {
        return {
          url: 'api/mezzanine/v2/sites/summary',
          params: {
            from: format(new Date(arg.from), 'yyyy-MM-dd'),
            to: format(new Date(arg.to), 'yyyy-MM-dd'),
            zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...(arg.siteIds && { siteIds: arg.siteIds }),
          },
          method: 'GET',
        };
      },
      providesTags: ['CalendarSummary'],
    }),
    fetchCalendarUnavailability: builder.query<CalendarUnavailability[], FetchUnavailabilityArgs>({
      query: (arg) => ({
        url: `api/henge/unavailability/start/${formatISO(new Date(arg.from))}/end/${formatISO(new Date(arg.to))}`,
        method: 'GET',
      }),
      transformResponse: (response: CalendarUnavailability[]) => {
        return response.map((unavailability) => ({
          ...unavailability,
          siteId: unavailability.siteId.toString(),
        }));
      },
      providesTags: ['CalendarUnavailability'],
    }),
    postCalendarUnavailability: builder.mutation<CalendarUnavailability[], PostCalendarUnavailabilityArgs>({
      query: (arg) => ({
        url: 'api/henge/unavailability/site',
        method: 'POST',
        body: {
          siteId: Number(arg.siteId),
          declarationStart: formatISO(new Date(arg.start)),
          declarationEnd: formatISO(new Date(arg.end)),
          unavailabilityType: 'normal',
        },
      }),
      transformResponse: (response: CalendarUnavailability[]) => {
        return response.filter(Boolean).map((unavailability) => ({
          ...unavailability,
          siteId: unavailability.siteId.toString(),
        }));
      },
    }),
  }),
});

export const {
  useFetchCalendarSummaryQuery,
  useLazyFetchCalendarSummaryQuery,
  useFetchCalendarUnavailabilityQuery,
  useLazyFetchCalendarUnavailabilityQuery,
  usePostCalendarUnavailabilityMutation,
} = calendarApi;
