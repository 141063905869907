import type { DropdownElement } from '../../types';

import React from 'react';

import { NJIcon } from '@engie-group/fluid-design-system-react';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';

interface DropdownProps {
  label: string;
  icon?: string;
  elements: DropdownElement[];
  dataTestId?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ label, icon, elements, dataTestId }) => {
  return (
    <div data-testid={dataTestId || 'dropdown-menu'} className="position-relative">
      <Menu>
        {({ open }) => (
          <React.Fragment>
            <Menu.Button
              as="div"
              data-testid={`${dataTestId}-button` || 'dropdown-menu-button'}
              className="position-relative d-flex align-items-center gap-4 t-base cursor-pointer"
            >
              {icon ? <NJIcon name={icon} /> : null}
              {label}
              <NJIcon name={`arrow_drop_${open ? 'up' : 'down'}`} />
            </Menu.Button>
            <Menu.Items as="div" className="d-flex flex-column bg-white position-absolute mt-2 d-flex w-full nj-shadow-2dp z-100">
              {elements.map((element, index) => (
                <Menu.Item
                  key={`${element.label}-${index}`}
                  as="div"
                  className={clsx('d-flex text-gray-light-0 cursor-pointer px-2 py-1 text-base', {
                    'border-bottom': index !== elements.length - 1,
                  })}
                  onClick={element.onClick}
                >
                  {element.label}
                </Menu.Item>
              ))}
            </Menu.Items>
          </React.Fragment>
        )}
      </Menu>
    </div>
  );
};
