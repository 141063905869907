import type { Contact, RootState, Site } from '../../types';

import { createSelector } from '@reduxjs/toolkit';

const dataContact = (state: RootState) => state.contact;

export const selectContactByType = createSelector(
  [dataContact, (_: RootState, contactType: Contact['type']) => contactType],
  (data, contactType) => data?.contacts.filter((c) => c.type === contactType)
);

export const selectContactBySiteId = createSelector(
  [dataContact, (_: RootState, siteId: Site['siteId'] | undefined) => siteId],
  (data, siteId) => data?.contacts.filter((c) => c.siteIds?.includes(Number(siteId)))
);

export const selectContactBySiteIds = createSelector(
  [dataContact, (_: RootState, siteIds: Site['siteId'][]) => siteIds],
  (data, siteIds) => data?.contacts.filter((contact) => contact.siteIds?.some((siteId) => siteIds.includes(String(siteId))))
);
