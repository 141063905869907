import React from 'react';

export function useOutsideClick(ref: React.RefObject<HTMLDivElement>, onClickOut: () => void, deps: never[] = []) {
  React.useEffect(() => {
    const onClick = (event: MouseEvent) => {
      return !ref?.current?.contains(event.target as Node) && onClickOut?.();
    };

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickOut, ref, ...deps]);
}
