import type {
  BalanceSheetData,
  BalanceSheetLineItem,
  EarningsStatement,
  FinancialReport,
  FinancialReportSummary,
  RootState,
  SiteId,
} from '../../types';

import { createSelector } from '@reduxjs/toolkit';
import { intervalToDuration } from 'date-fns';
import { t } from 'i18next';

import { dataSite, siteReportsBySitesAndYear, sortedSites } from '../site';
import { calculateInitialEarnings, calculatePenalty, calculateTotalEarnings } from '../utils';

const initialValue: FinancialReportSummary = {
  totalRevenueInc: 0,
  totalRevenueExc: 0,
  initialRevenue: 0,
  totalPenalty: 0,
  gcSalesIncome: 0,
  gcTotal: 0,
  gcRemaining: 0,
  totalEnergyOrDuration: 0,
};

// OK - tested
export const earningsReportSummary = (sum: FinancialReportSummary, curr: EarningsStatement) => {
  const initialEarning = calculateInitialEarnings(curr);
  const totalPenalty = calculatePenalty(curr);
  const totalEarning = calculateTotalEarnings(curr);

  return {
    totalRevenueInc: sum.totalRevenueInc + totalEarning,
    totalRevenueExc: sum.totalRevenueExc + curr.total_ht,
    initialRevenue: sum.initialRevenue + initialEarning,
    totalPenalty: sum.totalPenalty + totalPenalty,
    gcSalesIncome: sum.gcSalesIncome + (curr.gc_rem_amount || curr.garantie_capacity_rem_amount),
    gcTotal: 0,
    gcRemaining: 0,
    totalEnergyOrDuration: sum.totalEnergyOrDuration + curr.total_energy_or_duration,
  };
};

// OK - tested
export const financialReportSummary = createSelector([siteReportsBySitesAndYear], (reports) => {
  return reports.flatMap((report) => report.earning_reports).reduce(earningsReportSummary, initialValue);
});

// OK - tested
export const financialReportOverview = createSelector([dataSite, sortedSites, siteReportsBySitesAndYear], (data, sites, reports) => {
  return sites
    .filter((site) => data.selectedSites.includes(site.siteId) || data.selectedSites.length === 0)
    .map((site): FinancialReport => {
      const earningReports = reports
        .filter((siteReport) => siteReport.site_identifier === site.siteId)
        .flatMap((siteReport) => siteReport.earning_reports);

      const siteEarningsSummary = earningReports.reduce(earningsReportSummary, initialValue);

      return {
        prm: site.prm,
        siteId: site.siteId,
        siteName: site.siteName,
        totalEnergyOrDuration: siteEarningsSummary.totalEnergyOrDuration,
        totalRevenueInc: siteEarningsSummary.totalRevenueInc,
        totalRevenueExc: siteEarningsSummary.totalRevenueExc,
        reportCount: earningReports.length,
        gcTotal: 0,
        filenames: earningReports.map((report) => report.file_name),
      };
    });
});

// OK - tested
export const financialReportDetail = createSelector([siteReportsBySitesAndYear, (_: RootState, siteId: SiteId) => siteId], (reports, siteId) =>
  reports
    .filter((siteReport) => siteReport.site_identifier === siteId)
    .flatMap((siteReport) => siteReport.earning_reports)
    .map((report) => ({
      siteId,
      reportFilename: report.file_name,
      dateIssued: new Date(report.creation_datetime),
      income: report.total,
      gcTotal: 0,
    }))
);

// OK - tested
export const reportPeriodInMonths = (start: string, end: string) => {
  const { days, months } = intervalToDuration({
    start: new Date(start),
    end: new Date(end),
  });

  return (months ?? 0) + ((days ?? 0) >= 28 ? 1 : 0);
};

// OK - tested
export const balanceSheet = createSelector(
  [siteReportsBySitesAndYear, (_: RootState, params: { siteId: SiteId; reportFilename: string }) => params],
  (reports, params): BalanceSheetData =>
    reports
      .filter((report) => report.site_identifier === params.siteId)
      .flatMap((report) => report.earning_reports)
      .filter((report) => report.file_name === params.reportFilename)
      .map((report): BalanceSheetLineItem[] => {
        const months = reportPeriodInMonths(report.start_datetime, report.end_datetime);

        return [
          ...(report.rr_rem_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.remuneration-fixed'),
                  quantity: months,
                  unit: t('common:time-period.month', { count: months }),
                  amount: report.rr_rem_amount,
                },
              ]
            : []),
          ...(report.gc_rem_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.remuneration-variable'),
                  quantity: months,
                  unit: t('common:time-period.month', { count: months }),
                  amount: report.gc_rem_amount,
                },
              ]
            : []),
          ...(report.total_energy_or_duration !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.remuneration-energy'),
                  quantity: report.total_energy_or_duration,
                  unit: 'kWh',
                  amount: report.activation_rem_amount,
                },
              ]
            : []),
          ...(report.aoe_rem_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.remuneration-aoe'),
                  amount: report.aoe_rem_amount,
                },
              ]
            : []),
          ...(report.other_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.income-additional'),
                  amount: report.other_amount,
                },
              ]
            : []),
          ...(report.garantie_capacity_rem_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.gc-revenue'),
                  unit: t('financial-statements:balance-sheet.gc'),
                  amount: report.garantie_capacity_rem_amount,
                },
              ]
            : []),
          ...(report.penalty_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.penalty'),
                  amount: report.penalty_amount,
                },
              ]
            : []),
          ...(report.gc_penalty_amount !== 0
            ? [
                {
                  subject: t('financial-statements:balance-sheet.gc-penalty'),
                  amount: report.gc_penalty_amount,
                },
              ]
            : []),
          {
            total: t('financial-statements:balance-sheet.total-revenue-excluding-tax'),
            amount: report.total_ht,
          },
          {
            total: t('financial-statements:balance-sheet.tax'),
            amount: report.total_tva,
          },
          {
            total: t('financial-statements:balance-sheet.total-revenue-including-tax'),
            amount: report.total,
          },
        ];
      })
      .flat()
);
