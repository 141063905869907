import type { TypedUseSelectorHook } from 'react-redux';
import type { AppDispatch, RootState } from '../types';

import { useDispatch, useSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import { activationApi, activationReducer } from './activation';
import { calendarApi, calendarReducer } from './calendar';
import { chartApi, chartReducer } from './chart';
import { contactApi } from './contact';
import { contactReducer } from './contact/slice';
import { contractApi, contractReducer } from './contract';
import { globalReducer } from './global';
import { siteApi, siteReducer } from './site';
import { userApi, userReducer } from './user';

export const reducers = {
  // Slice reducers
  global: globalReducer,
  user: userReducer,
  site: siteReducer,
  contact: contactReducer,
  activation: activationReducer,
  chart: chartReducer,
  contract: contractReducer,
  calendar: calendarReducer,
};

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    ...reducers,

    // API reducers (RTK Query)
    [userApi.reducerPath]: userApi.reducer,
    [siteApi.reducerPath]: siteApi.reducer,
    [activationApi.reducerPath]: activationApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      siteApi.middleware,
      activationApi.middleware,
      contactApi.middleware,
      chartApi.middleware,
      contractApi.middleware,
      calendarApi.middleware
    );
  },
});

export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
