import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface ChartState {
  loading: boolean;
}

const initialState: ChartState = {
  loading: false,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers,
  extraReducers,
});

export const chartSelector = (state: { chart: ChartState }) => state.chart;
export const chartReducer = chartSlice.reducer;

export const { setChartLoading } = chartSlice.actions;
