import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { ContractState } from './slice';

import { contractApi } from './service';

export const reducers: CreateSliceOptions<ContractState>['reducers'] = {
  setContractsLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
} as const;

export const extraReducers: CreateSliceOptions<ContractState>['extraReducers'] = (builder: ActionReducerMapBuilder<ContractState>) => {
  return builder.addMatcher(contractApi.endpoints.fetchContracts.matchFulfilled, (state, { payload }) => {
    state.contracts = payload;
  });
};
