import type { CalendarSummary, CalendarUnavailability } from '../../types';

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers, reducers } from './reducer';

export interface CalendarState {
  loading: boolean;
  summary: CalendarSummary[];
  selectedFromDate: Date | null;
  selectedToDate: Date | null;
  from?: string;
  to?: string;
  selectedSite?: string;
  unavailability: CalendarUnavailability[];
}

const initialState: CalendarState = {
  loading: false,
  summary: [],
  selectedFromDate: null,
  selectedToDate: null,
  unavailability: [],
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers,
  extraReducers,
});

export const calendarSelector = (state: { calendar: CalendarState }) => state.calendar;
export const calendarReducer = calendarSlice.reducer;
export const calendarActions = calendarSlice.actions;

export const {
  setCalendarLoading,
  setCalendarSelectedFromDate,
  setCalendarSelectedToDate,
  setCalendarFromDate,
  setCalendarToDate,
  setCalendarSelectedSite,
} = calendarSlice.actions as (typeof calendarSlice)['actions'];
