import type { RootState } from '../../types';

import { createSelector } from '@reduxjs/toolkit';

const contractStore = (state: RootState) => state.contract;
const selectedYear = (state: RootState) => state.site.selectedYear;

export const contractDocsSelector = createSelector([contractStore, selectedYear], (store, selectedYear) => {
  return store.contracts.filter((contract) => contract.year === selectedYear);
});
